/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext, useRef } from "react";
import AboutContent from "../elements/aboutContent";
import { AboutAnimated } from "../styles/animations";
/** @ts-ignore **/
import { HappyMan, FulfilledMan } from "../hooks/images";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
// import { create } from "@lottiefiles/lottie-interactivity";

const About = () => {
  const state = useContext(GlobalStateContext);
    // const ref = useRef(null);
  return (
    <div>
      <span
        sx={{
          height: [`8rem`, `6rem`, `3rem`, `8rem`, `0rem`],
          display: `flex`,
        }}
      >{``}</span>
      <AboutContent>
        <AboutAnimated>
          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              width: `1/2`,
            }}
          >
            <h3
              sx={{
                fontFamily: `Libre Baskerville`,
                fontSize: [3, 3, 6, 7, 7],
                textAlign: `left`,
                fontWeight: `normal`,
                textTransform: `uppercase`,
                lineHeight: `1`,
              }}
            >
              THIS ISN&#39;T
              <br />
            </h3>
            <h3
              sx={{
                fontFamily: `Libre Baskerville`,
                fontSize: [3, 3, 6, 7, 7],
                textAlign: `left`,
                fontWeight: `normal`,
                textTransform: `uppercase`,
                lineHeight: `1`,
                pb: [3, 3, 0, 0],
              }}
            >
              COACHING.
            </h3>
            <div
              sx={{
                width: `full`,
                mr: [4, 4, 0, 0],
                py: [0, 0, `2rem`, `3rem`, `3rem`],
                px: [0, 0, `2rem`, `2rem`, `3rem`],
                position: `relative`,
              }}
            >
              <FulfilledMan />
              {typeof window !== "undefined" &&
                (state.viewport == "largeLaptop" ||
                  state.viewport == "laptop" ||
                  state.viewport == "tablet" ||
                  state.viewport == "desktop") && (
                  <p
                    sx={{
                      position: `absolute`,
                      width: `full`,
                      fontFamily: `Libre Baskerville`,
                      textAlign: `left`,
                      fontSize: [2, 2, 2, 4, 5],
                      lineHeight: "1.25",
                      mt: 5,
                    }}
                  >
                    This work is for men who <br />
                    desire more for themselves, <br />
                    their life &amp; their legacy.
                  </p>
                )}
            </div>
            {typeof window !== "undefined" &&
              (state.viewport == "mobile" ||
                state.viewport == "smallMobile") && (
                <p
                  sx={{
                    width: `full`,
                    fontFamily: `Libre Baskerville`,
                    textAlign: `left`,
                    fontSize: [`.80rem`, 0],
                    lineHeight: "1.25",
                    mt: 4,
                    ml: 4,
                  }}
                >
                  This work is for men
                  <br />
                  who desire more for
                  <br />
                  themselves, their life
                  <br />
                  &amp; their legacy.
                </p>
              )}
          </div>

          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              width: `1/2`,
              mt: `2rem`,
            }}
          >
            <div
              sx={{
                mt: `1rem`,
                mb: [2, 2, 4, 5],
                padding: `1rem`,
                height: `100px`,
              }}
            >
              <hr
                sx={{
                  backgroundColor: `black`,
                  color: `black`,
                  transform: `rotate(90deg)`,
                  width: [100, 100, 150, 150],
                  height: [`2px`, `2px`, `3px`, `3px`],
                }}
              />
            </div>
            <h3
              sx={{
                fontFamily: `Libre Baskerville`,
                fontSize: [3, 3, 6, 7, 7],
                fontWeight: `normal`,
                textAlign: `right`,
                lineHeight: `1`,
                mb: 3,
                mr: 3,
                alignSelf: [`normal`, `noraml`, `center`, `center`],
              }}
            >
              THIS IS <br />
              <em
                sx={{
                  fontWeight: `normal`,
                  fontSize: [`2rem`, `2rem`, `4rem`, `5rem`, `5rem`],
                  m: 0,
                }}
              >
                freedom.
              </em>
            </h3>
            <div
              sx={{
                width: `full`,
                height: [400, 400, 600, 900, 950],
                py: [0, 0, `2rem`, `3rem`, `3rem`],
                px: [0, 0, `2rem`, `2rem`, `3rem`],
              }}
            >
              <HappyMan />
            </div>
          </div>
        </AboutAnimated>
        <div
          sx={{
            width: `full`,
            height: `300px`,
            mt: [`-6rem`, `-6rem`, `-10rem`, `-3rem`, -6],
          }}
        >
          {typeof window !== "undefined" && state.viewport == "smallMobile" && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              sx={{
                fill: marsTheme.colors.marsRed,
                position: `absolute`,
                zIndex: 0,
              }}
            >
              <circle cx="50" cy="50" r="40" />
            </svg>
          )}
          {typeof window !== "undefined" && state.viewport == "mobile" && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              sx={{
                fill: marsTheme.colors.marsRed,
                position: `absolute`,
                zIndex: 0,
              }}
            >
              <circle cx="50" cy="50" r="40" />
            </svg>
          )}
          {typeof window !== "undefined" && state.viewport == "tablet" && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              sx={{
                fill: marsTheme.colors.marsRed,
                position: `absolute`,
                zIndex: 0,
              }}
            >
              <circle cx="50" cy="40" r="27" />
            </svg>
          )}
          {typeof window !== "undefined" && state.viewport == "laptop" && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              sx={{
                fill: marsTheme.colors.marsRed,
                position: `absolute`,
                zIndex: 0,
              }}
            >
              <circle cx="50" cy="27" r="27" />
            </svg>
          )}
          {typeof window !== "undefined" && state.viewport == "largeLaptop" && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              sx={{
                fill: marsTheme.colors.marsRed,
                position: `absolute`,
                zIndex: 0,
              }}
            >
              <circle cx="50" cy="31" r="20" />
            </svg>
          )}
        </div>
      </AboutContent>
    </div>
  );
};

export default About;
