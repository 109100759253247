/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";

type InnerProps = {
  className?: string;
  children: React.ReactNode;
};

const FounderInner = ({ className = ``, children }: InnerProps) => (
  <div
    sx={{
      width: `full`,
      textAlign: `left`,
      fontFamily: `Libre Baskerville`,
      height: `full`,
      padding: [0, 0, `0 3rem 0 3rem `, `0 3rem 0 3rem `],
    }}
    className={className}
  >
    {children}
  </div>
);

export default FounderInner;

export const P = styled.p`
  line-height: 1;
  margin: 0 0 1.5rem 0;
  color: ${marsTheme.colors.clay};
  font-family: Libre Baskerville;
  font-size: 1rem;
  @media (max-width: 500px) {
    font-size: 0.90rem;
    line-height: 1.25;
  }
  @media (min-width: 900px) {
    font-size: 1.25rem;
  }
  @media (min-width: 1200px) {
    font-size: 1.5rem;
    margin: 0 0 3rem 0;
    line-height: 1.25;
  }
`;

export const P2 = styled.p`
  line-height: 1;
  margin: 0 0 1.5rem 0;
  color: ${marsTheme.colors.clay};
  font-family: Libre Baskerville;
  font-size: 1.75rem;
  @media (min-width: 900px) {
    font-size: 2.75rem;
    margin: 0 0 5rem 0;
  }
  @media (min-width: 1200px) {
    font-size: 4rem;
    margin: 0 0 5rem 0;
  }
`;

export const P3 = styled.p`
  line-height: 1.3;
  margin: 0 0 1.5rem 0;
  color: ${marsTheme.colors.clay};
  font-family: Libre Baskerville;
  font-size: 1.75rem;
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
  @media (min-width: 900px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1200px) {
    font-size: 3rem;
    margin: 0 0 5rem 0;
  }
  @media (min-width: 1600px) {
    font-size: 3rem;
    margin: 2rem 0 5rem 0;
  }
`;
