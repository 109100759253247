/** @jsx jsx */
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Global } from "@emotion/core";
import { jsx } from "theme-ui";
import SEO from "./seo";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { CTABurnt } from "../hooks/images";

type LayoutProps = { children: React.ReactNode; className?: string };

const Layout = ({ children, className = `Layout` }: LayoutProps) => {
  const state = useContext(GlobalStateContext);
  const [isScrolled, setScrolled] = useState(null);
  const [ctaScroll, setCtaScroll] = useState(null);
  const listenScrollEvent = (e) => {
    if (
      typeof window !== "undefined" &&
      (state.viewport == "mobile" || state.viewport == "smallMobile")
    ) {
      if (window.scrollY < 656) {
        setCtaScroll(false);
      } else if (window.scrollY >= 656) {
        setCtaScroll(true);
      }
      if (window.scrollY <= 2280) {
        setScrolled(false);
      } else if (window.scrollY > 2280) {
        setScrolled(true);
      }
    } else if (typeof window !== "undefined" && state.viewport == "tablet") {
      if (window.scrollY < 830) {
        setCtaScroll(false);
      } else if (window.scrollY >= 830) {
        setCtaScroll(true);
      }
      if (window.scrollY <= 2600) {
        setScrolled(false);
      } else if (window.scrollY > 2600) {
        setScrolled(true);
      }
    } else if (typeof window !== "undefined" && state.viewport == "laptop") {
      if (window.scrollY < 1090) {
        setCtaScroll(false);
      } else if (window.scrollY >= 1090) {
        setCtaScroll(true);
      }
      if (window.scrollY <= 3115) {
        setScrolled(false);
      } else if (window.scrollY > 3115) {
        setScrolled(true);
      }
    } else if (
      typeof window !== "undefined" &&
      (state.viewport == `largeLaptop` || state.viewport == `desktop`)
    ) {
      if (window.scrollY < 720) {
        setCtaScroll(false);
      } else if (window.scrollY >= 720) {
        setCtaScroll(true);
      }
      if (window.scrollY <= 4430) {
        setScrolled(false);
      } else if (window.scrollY > 4430) {
        setScrolled(true);
      }
    } else {
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  });

  return (
    <React.Fragment>
      <Global
        styles={(theme) => ({
          "*": {
            boxSizing: `inherit`,
            "&:before": {
              boxSizing: `inherit`,
            },
            "&:after": {
              boxSizing: `inherit`,
            },
          },
          html: {
            fontSize: `18px`,
            WebkitTextSizeAdjust: `100%`,
            fontFamily: `Libre Baskerville`,
            scrollBehavior: `smooth`,
          },
          body: {
            backgroundColor:
              isScrolled === true
                ? marsTheme.colors.black
                : marsTheme.colors.clay,
            transition: `all 1600ms ease`,
            WebkitTransition: `all 1600ms ease`,
            MozTransition: `all 1600ms ease`,
            WebkitFontSmoothing: `auto`,
            MozOsxFontSmoothing: `auto`,
            margin: 0,
            fontFamily: `Libre Baskerville`,
          },

          a: {
            backgroundColor: `transparent`,
            transition: `all 0.3s ease-in-out`,
            "&:hover": {
              transform: `translateY(-5px)`,
              transition: `.5s`,
            },
          },

          h3: {
            margin: `0`,
          },
          img: {
            borderStyle: `none`,
          },
          pre: {
            fontFamily: `Libre Baskerville`,
            fontSize: `1em`,
          },
          "[hidden]": {
            display: `none`,
          },
          "::selection": {
            backgroundColor: theme.colors.background,
            color: theme.colors.primary,
          },
        })}
      />
      <SEO />

      <main className={className}>
        <div
          sx={{
            position: ctaScroll ? `fixed` : `absolute`,
            top: ctaScroll ? `1%` : `80%`,
            width: `5rem`,
            height: `5rem`,
            zIndex: `100`,
            display: isScrolled === true ? `none` : `inherit`,
            // display: `none`,
          }}
        >
          <a
            href="https://calendly.com/marscontract/discovery-call"
            target="_blank"
            referrerPolicy="strict-origin-when-cross-origin"
            rel="noreferrer"
          >
            <CTABurnt />
          </a>
        </div>

        {children}
      </main>
    </React.Fragment>
  );
};

export default Layout;
