/** @jsx jsx */
// @ts-nocheck
import React, { useContext, useRef, useEffect } from "react";
import { jsx } from "theme-ui";
import Content from "../elements/content";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { SiInstagram } from "react-icons/si";
import { CTAWhite, LogoWordMark, WordMark } from "../hooks/images";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { create } from "@lottiefiles/lottie-interactivity";

const Contact = () => {
  const state = useContext(GlobalStateContext);
  const ref4 = useRef(null);
  const currentDate = new Date().getFullYear();

  const scrollToTop = () => {
    if (typeof window != "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const lottieScroll3 = (e) => {
    create({
      mode: "scroll",
      player: "#fourthLottie",
      container: "#lottieWrapper4",
      actions: [
        {
          visibility: [0, 1],
          type: "stop",
          frames: [100],
        },
        // {
        //   visibility: [0, 0.1],
        //   type: "stop",
        //   frames: [0],
        // },
        // {
        //   visibility: [0.1, 1],
        //   type: "seek",
        //   frames: [0, 100],
        // },
      ],
    });
  };
  useEffect(() => {
    import("@lottiefiles/lottie-player");
    ref4.current && ref4.current.addEventListener("load", lottieScroll3);

    return () => {
      ref4.current && ref4.current.removeEventListener("load", lottieScroll3);
    };
  }, []);

  return (
    <>
      <Content
        className="Contact Content"
        fontFamily="Libre Baskerville"
        color={marsTheme.colors.clay}
      >
        {/* <MOBILE></MOBILE> */}
        {/* <MOBILE></MOBILE> */}
        {/* <MOBILE></MOBILE> */}
        {typeof window != "undefined" &&
          (state.viewport == "mobile" ||
            state.viewport == "smallMobile" ||
            state.viewport == "tablet") && (
            <div
              sx={{
                textAlign: `left`,
                width: `full`,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <h3
                sx={{
                  height: `1/5`,
                  fontSize: [`1.5rem`, `1.5rem`, `3rem`, `4rem`],
                  lineHeight: `1.3`,
                  ml: [4, 4, 4, 5, 5],
                  alignSelf: `flex-start`,
                  color: marsTheme.colors.clay,
                  fontWeight: `normal`,
                  my: [`2rem`, `2rem`, `2rem`, `2rem`, `2rem`],
                }}
              >
                Your journey
                <br /> has led you <br />
                <em>right here.</em>
              </h3>
              <div
                sx={{
                  width: `full`,
                  mb: 5,
                  justifyContent: `space-between`,
                  display: `flex`,
                }}
              >
                <div
                  sx={{
                    mt: [3, 3, 5],
                    width: [`1/2`, `1/2`, `1/3`],
                    display: `flex`,
                    justifyContent: `flex-end`,
                    mr: [`1rem`, 4],
                  }}
                >
                  <svg height="200" width="100">
                    <polyline
                      points="15,0 15,0 15 ,150 85,150 130"
                      stroke="white"
                      strokeWidth=".75px"
                      fill="transparent"
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                {/* <div
                  id="lottieWrapper4"
                  sx={{
                    mt: [3, 3, 5],
                    width: [`1/2`, `1/2`, `1/3`],
                    mr: [0, 4],
                  }}
                >
                  <lottie-player
                    id="fourthLottie"
                    ref={ref4}
                    mode="normal"
                    src="https://assets7.lottiefiles.com/packages/lf20_cgogcdew.json"
                    style={{ height: "50vh" }}
                  ></lottie-player>
                </div> */}
                <OutboundLink
                  sx={{
                    width: [`1/2`, `1/2`],
                    mt: 4,
                  }}
                  href="https://calendly.com/marscontract/discovery-call?galink=contact_ctawhite_mobile"
                  target="_blank"
                  referrerPolicy="strict-origin-when-cross-origin"
                  rel="noreferrer"
                >
                  <CTAWhite />
                </OutboundLink>
              </div>

              <div
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  mx: 3,
                }}
              >
                <div
                  sx={{
                    fontSize: [1, 1, 3],
                    color: marsTheme.colors.clay,
                    textAlign: `center`,
                    lineHeight: `1.25`,
                  }}
                >
                  <h3
                    sx={{
                      mb: 3,
                    }}
                  >
                    Launch your life&#39;s
                    <br />
                    legacy today.
                  </h3>
                  <OutboundLink
                    href="https://calendly.com/marscontract/discovery-call?galink=contact_legacybutton_mobile"
                    target="_blank"
                    referrerPolicy="strict-origin-when-cross-origin"
                    rel="noreferrer"
                  >
                    <button
                      sx={{
                        bg: `transparent`,
                        height: `3rem`,
                        width: [`9rem`, `9rem`, `12rem`],
                        border: `1px solid ${marsTheme.colors.clay}`,
                        borderRadius: `50px`,
                      }}
                    >
                      <p
                        sx={{
                          m: 0,
                          lineHeight: `1`,
                          fontSize: [`.65rem`, `.65rem`, 1],
                          fontFamily: `Libre Baskerville`,
                          color: marsTheme.colors.clay,
                        }}
                      >
                        <em>Book your discovery call.</em>
                      </p>
                    </button>
                  </OutboundLink>
                  <div
                    sx={{
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      marginBottom: `10px`,
                    }}
                  >
                    <div
                      sx={{
                        my: `1rem`,
                      }}
                    >
                      <a
                        href="mailto:hello@themarscontract.com"
                        target="_blank"
                        referrerPolicy="strict-origin-when-cross-origin"
                        rel="noreferrer"
                      >
                        <p
                          sx={{
                            fontSize: [`.66rem`, `.66rem`, 1],
                            color: marsTheme.colors.clay,
                          }}
                        >
                          hello@themarscontract.com
                        </p>
                      </a>
                    </div>
                    <IconsMobile />
                  </div>
                  <Link
                    to="/privacy-policy"
                    sx={{
                      fontSize: `12px`,
                      color: marsTheme.colors.clay,
                    }}
                  >
                    Privacy Policy
                  </Link>
                </div>

                <div
                  sx={{
                    width: `full`,
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignContent: `center`,
                    alignItems: `center`,
                    py: 4,
                  }}
                >
                  <span
                    sx={{
                      width: `20%`,
                    }}
                  >
                    <p
                      sx={{
                        m: 0,
                        fontSize: `12px`,
                      }}
                    >
                      &copy; {currentDate}
                    </p>
                  </span>

                  <div sx={{ width: `20%` }}>
                    <Link to="#">
                      <LogoWordMark />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* LARGELAPTOP */}
        {/* LARGELAPTOP */}
        {/* LARGELAPTOP */}
        {/* LARGELAPTOP */}
        {typeof window != "undefined" &&
          (state.viewport == "largeLaptop" || state.viewport == "laptop") && (
            <div
              sx={{
                textAlign: `left`,
                width: `full`,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <div
                sx={{
                  display: `flex`,
                }}
              >
                <h3
                  sx={{
                    fontSize: [`1.5rem`, `1.5rem`, `3rem`, 6, `4rem`],
                    lineHeight: `1.3`,
                    ml: [4, 4, 5, 5, 5],
                    alignSelf: `flex-start`,
                    color: marsTheme.colors.clay,
                    fontWeight: `normal`,
                    my: `2rem`,
                  }}
                >
                  Your journey
                  <br /> has led you <br />
                  <em>right here.</em>
                </h3>
                <div
                  sx={{
                    mt: [5, 5, 5, 6, `11rem`],
                    width: [`1/4`, `1/4`, `1/2`],
                    display: `flex`,
                    justifyContent: `flex-end`,
                    mr: [0, 4],
                  }}
                >
                  {typeof window != "undefined" && state.viewport == "laptop" && (
                    <svg height="300" width="400">
                      <polyline
                        points="0,40 340,40 340,80 340,80 340,350 160"
                        stroke="#F2F9EF"
                        strokeWidth="2px"
                        fill="transparent"
                      />
                      Sorry, your browser does not support inline SVG.
                    </svg>
                  )}
                  {typeof window != "undefined" &&
                    (state.viewport == "largeLaptop" ||
                      state.viewport == "desktop") && (
                      <svg height="400" width="600">
                        <polyline
                          points="0,1 600,1 600,80 600,80 600,350 160"
                          stroke="#F2F9EF"
                          strokeWidth="2px"
                          fill="transparent"
                        />
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    )}
                </div>
              </div>

              <div
                sx={{
                  width: `full`,
                  mb: 5,
                  justifyContent: `space-between`,
                  display: `flex`,
                }}
              >
                <div
                  sx={{
                    alignSelf: `flex-end`,
                    margin: `0 auto`,
                  }}
                >
                  <h3
                    sx={{
                      mb: 4,
                      fontSize: [2, 3, 4, 5, 6],
                      textAlign: `center`,
                      lineHeight: `1.25`,
                    }}
                  >
                    Launch your <br />
                    life&#39;s legacy <br />
                    today.
                  </h3>
                  <div
                    sx={{
                      fontSize: `1rem`,
                      color: marsTheme.colors.clay,
                      textAlign: `center`,
                      lineHeight: `1`,
                      mb: 4,
                      "&:hover": {
                        transform: `translateY(-5px)`,
                        transition: `.5s`,
                      },
                    }}
                  >
                    <OutboundLink
                      href="https://calendly.com/marscontract/discovery-call?=contact_legacybutton_desktop"
                      target="_blank"
                      referrerPolicy="strict-origin-when-cross-origin"
                      rel="noreferrer"
                    >
                      <button
                        sx={{
                          bg: `transparent`,
                          height: `5rem`,
                          width: `15rem`,
                          padding: 3,
                          border: `1px solid ${marsTheme.colors.clay}`,
                          borderRadius: `50px`,
                          color: marsTheme.colors.clay,
                          cursor: `pointer`,
                          "&:hover": {
                            border: `3px solid ${marsTheme.colors.marsRed}`,
                          },
                        }}
                      >
                        <p
                          sx={{
                            m: 0,
                            lineHeight: `1`,
                            fontSize: 2,
                            fontFamily: `Libre Baskerville`,
                            "&:hover": {
                              color: marsTheme.colors.marsRed,
                            },
                          }}
                        >
                          <em>Book your discovery call.</em>
                        </p>
                      </button>
                    </OutboundLink>
                  </div>
                  <div
                    sx={{
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      margin: `auto`,
                    }}
                  >
                    <a
                      href="mailto:hello@themarscontract.com"
                      target="_blank"
                      referrerPolicy="strict-origin-when-cross-origin"
                      rel="noreferrer"
                      // onClick={emailClick}
                    >
                      <p
                        sx={{
                          fontSize: `1rem`,
                          color: marsTheme.colors.clay,
                          "&:hover": {
                            color: marsTheme.colors.marsRed,
                          },
                        }}
                      >
                        hello@themarscontract.com
                      </p>
                    </a>

                    <Icons />
                  </div>
                </div>
                <OutboundLink
                  sx={{
                    width: `35%`,
                    mt: 4,
                  }}
                  href="https://calendly.com/marscontract/discovery-call?galink=contact_ctawhite_desktop"
                  target="_blank"
                  referrerPolicy="strict-origin-when-cross-origin"
                  rel="noreferrer"
                >
                  <CTAWhite />
                </OutboundLink>
              </div>
              {/* FOOTER */}
              {/* FOOTER */}
              {/* FOOTER */}
              {/* FOOTER */}
              <div
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  mx: 5,
                  marginTop: 5,
                }}
              >
                <div
                  sx={{
                    width: `full`,
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignContent: `center`,
                    alignItems: `center`,

                    py: 4,
                  }}
                >
                  <span
                    sx={{
                      textAlign: `center`,
                    }}
                  >
                    <p
                      sx={{
                        fontSize: `12px`,
                      }}
                    >
                      &copy; {currentDate}, The Mars Contract
                    </p>
                  </span>
                  <button
                    onClick={scrollToTop}
                    sx={{
                      textAlign: `center`,
                      backgroundColor: `transparent`,
                      border: `none`,
                      cursor: `pointer`,
                    }}
                  >
                    <p
                      sx={{
                        fontSize: `12px`,
                        color: marsTheme.colors.clay,
                        fontStyle: `italic`,
                        textDecoration: `underline`,
                        lineHeight: `1`,
                        textUnderlineOffset: 1,
                        p: 10,
                        "&:hover": {
                          transform: `translateY(-5px)`,
                          transition: `.5s`,
                        },
                      }}
                    >
                      Back to top
                    </p>
                    <Link
                      to="/privacy-policy"
                      sx={{
                        fontSize: `12px`,
                        color: marsTheme.colors.clay,
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </button>

                  <div sx={{ width: `10%` }}>
                    <Link to="#">
                      <WordMark />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
      </Content>
    </>
  );
};

export default Contact;
//TODO: LInks to social media sites
const Icons = () => (
  <IconContext.Provider value={{ color: marsTheme.colors.clay, size: "1.5em" }}>
    <div
      sx={{
        display: `flex`,
        justifyContent: `space-evenly`,
        flexDirection: "row",
        alignItems: `baseline`,
        width: `35%`,
        mt: 2,
      }}
    >
      <OutboundLink
        href="https://www.instagram.com/g_lyonns/"
        target="_blank"
        referrerPolicy="strict-origin-when-cross-origin"
        rel="noreferrer"
      >
        <SiInstagram />
      </OutboundLink>
      <OutboundLink
        href="https://www.facebook.com/theMarsContract"
        target="_blank"
        referrerPolicy="strict-origin-when-cross-origin"
        rel="noreferrer"
      >
        <FaFacebook />
      </OutboundLink>
    </div>
  </IconContext.Provider>
);
const IconsMobile = () => (
  <IconContext.Provider
    value={{ color: marsTheme.colors.clay, size: "1.15rem" }}
  >
    <div
      sx={{
        width: `1/2`,
        display: `flex`,
        justifyContent: ` space-evenly`,
      }}
    >
      <OutboundLink
        href="https://www.instagram.com/g_lyonns/"
        target="_blank"
        referrerPolicy="strict-origin-when-cross-origin"
        rel="noreferrer"
      >
        <SiInstagram />
      </OutboundLink>
      <OutboundLink
        href="https://m.facebook.com/theMarsContract"
        target="_blank"
        referrerPolicy="strict-origin-when-cross-origin"
        rel="noreferrer"
      >
        <FaFacebook />
      </OutboundLink>
    </div>
  </IconContext.Provider>
);
