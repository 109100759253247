/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type TitleProps = {
  children: React.ReactNode;
  className?: string;
};

const Title = ({ children, className = `title` }: TitleProps) => (
  <div
    sx={{
      mt: [`6rem`, `6rem`, `10rem`,`7rem`, `6rem`],
      mb: [`5rem`, `10rem`, `7rem`, `14rem`],
      pt: [0, 1, 3, 3],
      pb: [0, 1, 3, 3],
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      width: `90%`,
      zIndex: 50,
      textAlign: [`center`, `center`, `unset`, `unset`],
    }}
    className={className}
  >
    {children}
  </div>
);

export default Title;
