/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type DividerProps = {
  children?: React.ReactNode;
};

const Divider = ({ children }: DividerProps) => (
  <div
    sx={{
      position: `absolute`,
      width: `full`,
    }}
  >
    {children}
  </div>
);

export default Divider;
