/** @jsx jsx **/
// @ts-nocheck
import React, { useContext, useRef, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import Content from "../elements/content";
import { P, P2, P3 } from "../elements/founderInner";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { LyonsMobile, LogoWordMarkFlat } from "../hooks/images";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { create } from "@lottiefiles/lottie-interactivity";

const Founder = () => {
  const state = useContext(GlobalStateContext);
  const [isScrolled, setScrolled] = useState(null);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [lottieHeight, setlottieHeight] = useState("60vh");

  const listenScrollEvent = (e) => {
    if (typeof window !== "undefined" && state.viewport == "laptop") {
      setlottieHeight("60vh");
      if (window.scrollY <= 3125) {
        setScrolled(false);
      } else if (window.scrollY > 3125) {
        setScrolled(true);
      } else {
      }
    } else if (
      typeof window !== "undefined" &&
      (state.viewport == "mobile" || state.viewport == "smallMobile")
    ) {
      setlottieHeight("30vh");
      if (window.scrollY <= 2300) {
        setScrolled(false);
      } else if (window.scrollY > 2300) {
        setScrolled(true);
      }
    } else if (
      typeof window !== "undefined" &&
      (state.viewport == `largeLaptop` || state.viewport == `desktop`)
    ) {
      setlottieHeight("60vh");
      if (window.scrollY <= 4440) {
        setScrolled(false);
      } else if (window.scrollY > 4440) {
        setScrolled(true);
      }
    } else if (
      typeof window !== "undefined" &&
      state.veiwport != "mobile" &&
      state.viewport != "laptop" &&
      state.viewport != "largeLaptop" &&
      state.viewport != "desktop"
    ) {
      setlottieHeight("60vh");
      if (window.scrollY <= 2610) {
        setScrolled(false);
      } else if (window.scrollY > 2610) {
        setScrolled(true);
      }
    }
  };
  const lottieScroll = (e) => {
    create({
      mode: "scroll",
      player: "#secondLottie",
      container: "#lottieWrapper2",
      actions: [
        {
          visibility: [0, 0.05],
          type: "stop",
          frames: [0],
        },
        {
          visibility: [0.05, 1],
          type: "seek",
          frames: [0, 100],
        },
      ],
    });
  };
  const lottieScroll2 = (e) => {
    create({
      mode: "scroll",
      player: "#thirdLottie",
      container: "#lottieWrapper3",
      actions: [
        // {
        //   visibility: [0, 1],
        //   type: "stop",
        //   frames: [100],
        // },
        {
          visibility: [0, 0.1],
          type: "stop",
          frames: [0],
        },
        {
          visibility: [0.1, 1],
          type: "seek",
          frames: [0, 100],
        },
      ],
    });
  };
  //TODO; Fix this funciton it is breakign in development
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    import("@lottiefiles/lottie-player");
    ref.current && ref.current.addEventListener("load", lottieScroll);
    ref2.current && ref2.current.addEventListener("load", lottieScroll2);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
      ref.current && ref.current.removeEventListener("load", lottieScroll);
      ref2.current && ref2.current.removeEventListener("load", lottieScroll2);
    };
  });
  return (
    <>
      <Content>
        <div
          sx={{
            width: `full`,
            fontFamily: `Libre Baskerville`,
            height: `full`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <div
            id="lottieWrapper2"
            sx={{
              display: `flex`,
              flexDirection: `column`,
              // mb: `2rem`,
              mr: [``, ``, ``, `2rem`, `8.33rem`],
              width: [`100%`, `100%`, `100%`, `31%`, `16%`],
              alignSelf: [`normal`, `normal`, `normal`, `flex-end`, `flex-end`],
              height: [`unset`, `unset`, `unset`, `unset`, 200],
            }}
          >
            <lottie-player
              id="secondLottie"
              ref={ref}
              mode="normal"
              src="https://assets5.lottiefiles.com/packages/lf20_hlrscybj.json"
              style={{ height: lottieHeight }}
            ></lottie-player>
          </div>

          <div
            sx={{
              zIndex: `1`,
              mb: `4rem`,
              display: `flex`,
              flexDirection: [`column`, `column`, `column`, `row`, `row`],
              alignItems: [`normal`, `normal`, `normal`, `center`],
              justifyContent: [
                `normal`,
                `normal`,
                `space-between`,
                `space-around`,
                `space-around`,
              ],
            }}
          >
            <div
              sx={{
                textAlign: `left`,
                alignSelf: `flex-start`,
                mt: 4,
                mb: 4,
                ml: [3, 3, 4, 4, 5],
              }}
            >
              <P2>
                THIS ISN&#39;T
                <br />
                COACHING.
              </P2>
              <P2>
                THIS IS
                <br />
                <em
                  sx={{
                    fontSize: [`2rem`, `2.5rem`, `3rem`, `4rem`, `6rem`],
                  }}
                >
                  transformation.
                </em>
              </P2>
            </div>
            <div
              sx={{
                display: `flex`,
                flexDirection: "column",
                alignItems: `center`,
                ml: 3,
                mt: 4,
              }}
            >
              <div
                sx={{
                  textAlign: `left`,
                  mb: 2,
                  alignSelf: `flex-start`,
                  mr: [3, 3, 4, 4, 6],
                }}
              >
                <h2
                  sx={{
                    fontSize: [`1.25rem`, `1.25rem`, `2rem`, `2rem`, `2rem`],
                    lineHeight: `1`,
                    m: 0,
                    color: marsTheme.colors.clay,
                  }}
                >
                  G. Lyonns
                </h2>
              </div>
              <div
                sx={{
                  display: `flex`,
                  width: [`3/5`, `3/5`, `3/4`, `120%`],
                  alignItems: [
                    `baseline`,
                    `baseline`,
                    `center`,
                    `baseline`,
                    `baseline`,
                  ],
                  justifyContent: [
                    `end`,
                    `end`,
                    `end`,
                    "space-evenly",
                    "space-evenly",
                  ],

                  alignSelf: [`flex-start`, `flex-start`, `center`],
                }}
              >
                <p
                  sx={{
                    color: marsTheme.colors.clay,
                    m: 0,
                    mr: [3, 3, 4],
                    textAlign: `center`,
                    fontStyle: `italic`,
                    fontSize: [1, 1, 3, 3, 3],
                  }}
                >
                  Founder
                </p>
                <div sx={{ width: [`1/2`, `2/3`, `3/5`, `4/5`] }}>
                  <LogoWordMarkFlat />
                </div>
              </div>
            </div>
          </div>

          <div
            sx={{
              width: [`60%`, `60%`, `40%`, `35%`, `40%`],
              zIndex: `0`,
              position: `absolute`,
              mt: [`18rem`, `18rem`, `24rem`, `24rem`, `12rem`],
              right: [0, 0, `35%`, `35%`],
            }}
          >
            <LyonsMobile />
          </div>

          <div
            sx={{
              textAlign: `left`,
              mb: 3,
              alignSelf: `flex-start`,
              marginLeft: [`2rem`, `2rem`, `4rem`, `4rem`, `8rem`],
              marginTop: [0, 0, `1rem`, `2rem`, `6rem`, `8rem`],
            }}
          >
            {typeof window != "undefined" &&
              (state.viewport == "mobile" ||
                state.viewport == "smallMobile") && (
                <P3>
                  I am <em>deeply</em> invested <br />
                  in restoring the <br />
                  power &amp; purpose <br />
                  of masculine energy <br />
                  on this planet.
                </P3>
              )}
            {typeof window != "undefined" &&
              (state.viewport == "tablet" ||
                state.viewport == "laptop" ||
                state.viewport == "largeLaptop" ||
                state.viewport == "desktop") && (
                <P3>
                  I am <em>deeply</em> invested in restoring the <br />
                  power &amp; purpose of masculine <br />
                  energy on this planet.
                </P3>
              )}
          </div>

          <div
            sx={{
              mb: 4,
              alignSelf: `flex-start`,
              marginLeft: [`2rem`, `2rem`, `4rem`, `4rem`, `8rem`],
              marginRight: [`2rem`, `2rem`, `0`, `0`, `0`],
              width: [`90%`, `90%`, `75%`, `60%`, `50%`],
            }}
          >
            <div
              sx={{
                width: `50%`,
                position: `absolute`,
                zIndex: 0,
                left: `50%`,
              }}
            >
              {typeof window !== "undefined" && state.viewport == "mobile" && (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 363.2 595.6"
                  sx={{
                    fill: marsTheme.colors.marsRed,
                    mt: `11rem`,
                    ml: `1rem`,
                  }}
                >
                  <path
                    d="M363.2,7.2v581.2c-21,4.7-42.9,7.2-65.4,7.2C133.3,595.6,0,462.3,0,297.8S133.3,0,297.8,0
                    C320.3,0,342.2,2.5,363.2,7.2z"
                  />
                </svg>
              )}
              {typeof window !== "undefined" &&
                state.viewport == "smallMobile" && (
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 363.2 595.6"
                    sx={{
                      fill: marsTheme.colors.marsRed,
                      mt: `13rem`,
                      ml: 4,
                    }}
                  >
                    <path
                      d="M363.2,7.2v581.2c-21,4.7-42.9,7.2-65.4,7.2C133.3,595.6,0,462.3,0,297.8S133.3,0,297.8,0
                    C320.3,0,342.2,2.5,363.2,7.2z"
                    />
                  </svg>
                )}
              {typeof window !== "undefined" &&
                (state.viewport == "laptop" || state.viewport == "tablet") && (
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 492 600.3"
                    sx={{
                      fill: marsTheme.colors.marsRed,
                      mt: `10rem`,
                    }}
                  >
                    <path
                      d="M492,44.8C442.7,16.4,384.6,0,322.4,0C144.3,0,0,134.4,0,300.1c0,165.8,144.3,300.1,322.4,300.1
    c62.2,0,120.3-16.4,169.6-44.8V44.8z"
                    />
                  </svg>
                )}
              {typeof window !== "undefined" &&
                (state.viewport == "largeLaptop" ||
                  state.viewport == "desktop") && (
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 492 600.3"
                    sx={{
                      fill: marsTheme.colors.marsRed,
                      mt: `10rem`,
                    }}
                  >
                    <path
                      d="M492,44.8C442.7,16.4,384.6,0,322.4,0C144.3,0,0,134.4,0,300.1c0,165.8,144.3,300.1,322.4,300.1
c62.2,0,120.3-16.4,169.6-44.8V44.8z"
                    />
                  </svg>
                )}
            </div>
            <P>
              {" "}
              Our world needs healthy, authentic <br />
              masculine leadership. Supporting the development of this while
              restoring the social contract of masculinity is my greatest
              priority in life.
            </P>
            <P>
              {" "}
              I am here to be an unwavering
              <br />
              advocate for you, your purpose, your potential, &amp; your ability
              to find and fulfill your deepest needs.
            </P>
            <P>
              {" "}
              The Mars Contract is fully
              <br /> committed to you <br />
              &amp; your success.
            </P>
          </div>

          <div
            sx={{
              alignSelf: `flex-start`,
              color: marsTheme.colors.clay,
              fontFamily: `Libre Baskerville`,
              fontSize: [5, 5, 6, 7],
              fontWeight: `normal`,
              marginLeft: [`1.3rem`, `1.5rem`, `4rem`, `4rem`, `8rem`],
              marginTop: [0, 0, 4, 4],
            }}
          >
            <em>Are you?</em>

            <div
              id="lottieWrapper3"
              sx={{
                display: `flex`,
                flexDirection: `column`,
                my: [3, 3, 4, 0, 0],
                mx: [0, 0, 0, 0, 0],
                mr: [50, 0, 0, 0, 0],
                ml: [0, 0, -50, -50, 0],
              }}
            >
              <lottie-player
                id="thirdLottie"
                ref={ref2}
                mode="normal"
                src="https://assets9.lottiefiles.com/packages/lf20_kvanbxmj.json"
                style={{ height: lottieHeight }}
              ></lottie-player>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Founder;
