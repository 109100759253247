/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext, useEffect, useRef } from "react";
import Divider from "../elements/divider";
import Content from "../elements/content";
import { UpDown } from "../styles/animations";
// @ts-ignore
import Title from "../elements/title";
import Slide from "react-reveal/Slide";
import { SandStone, MarsLogoWhite, MarsTextLogoWhite } from "../hooks/images";
import "../../font/fonts.css";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { Link } from "gatsby";
import { GlobalStateContext } from "../context/GlobalContextProvider";
// import { create } from "@lottiefiles/lottie-interactivity";
const Hero = ({ props }) => {
  const state = useContext(GlobalStateContext);
  // const ref = useRef(null);

  // useEffect(() => {
  //     import("@lottiefiles/lottie-player");
  // })
  return (
    <div>
      <header>
        <nav>
          <ul
            {...props}
            sx={{
              position: `absolute`,
              display: `flex`,
              justifyContent: `space-between`,
              width: `full`,
              listStyle: `none`,
              padding: `0`,
              mt: [4, 4, 5, 5],
              zIndex: `50`,
              alignItems: `center`,
            }}
          >
            <Slide ssrFadeout left delay={2000}>
              <li
                {...props}
                sx={{
                  width: [`9rem`, `9rem`, `10rem`, `11rem`],
                  pl: [3, 3, 4, 4],
                }}
              >
                <MarsTextLogoWhite />
              </li>
            </Slide>
            <Slide ssrFadeout right delay={2000}>
              <li
                {...props}
                sx={{
                  width: [`3rem`, `3rem`, `5rem `, `6rem`],
                  pr: [3, 3, 4, 4],
                  transition: `2s ease`,
                }}
              >
                <Link to="/">
                  <MarsLogoWhite />
                </Link>
              </li>
            </Slide>
          </ul>
        </nav>
      </header>

      <Divider>
        <UpDown
          {...props}
          sx={{
            width: `full`,
            margin: [`0 0 0 auto`, `0 0 0 auto`, `0 auto`],
            height: `full`,
          }}
        >
          <SandStone />
        </UpDown>
      </Divider>

      <Content {...props} sx={{ variant: `texts.bigger` }}>
        <Title>
          <div
            {...props}
            sx={{
              display: `flex`,
              flexDirection: `column`,
              width: `full`,
              mt: `2.5rem`,
              mb: [0, 5, 0, 0],
              ml: [0, 0, 5, 5],
              color: marsTheme.colors.clay,
            }}
          >
            <h1
              {...props}
              sx={{
                fontSize: [6, 6, 8, 100],
                margin: `0`,
                fontFamily: `Restora`,
                lineHeight: [`.9`, `.9`, `1`, `1.25`],
                alignSelf: `flex-start`,
                fontWeight: `bold`,
              }}
            >
              LEADING
            </h1>

            <h2
              {...props}
              sx={{
                fontSize: [6, 6, 8, 100],
                letterSpacing: `wide`,
                lineHeight: [`.9`, `.9`, `1`, `1.25`],
                fontFamily: `Restora`,
                fontStyle: `italic`,
                alignSelf: `flex-start`,
                m: 0,
                fontWeight: `100`,
              }}
            >
              MEN
            </h2>
          </div>
          {/* MOBILE & SMALLMOBILE */}
          {/* MOBILE & SMALLMOBILE */}
          {/* MOBILE & SMALLMOBILE */}
          {/* MOBILE & SMALLMOBILE */}
          {typeof window !== "undefined" && state.viewport == "mobile" && (
            <svg
              height="100"
              width="200"
              {...props}
              sx={{
                position: `absolute`,
                ml: 5,
                mt: `5rem`,
                transition: `all 2s ease`,
                WebkitTransition: `all 2s ease`,
                MozTransition: `all 2s ease`,
              }}
            >
              <polyline
                points="0,27 120,27 120,80 120,80 120,120 160"
                stroke="#F2F9EF"
                strokeWidth="2px"
                fill="transparent"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}
          {typeof window !== "undefined" && state.viewport == "smallMobile" && (
            <svg
              height="100"
              width="150"
              {...props}
              sx={{
                position: `absolute`,
                ml: `3rem`,
                mt: `5rem`,
                transition: `all 2s ease`,
                WebkitTransition: `all 2s ease`,
                MozTransition: `all 2s ease`,
              }}
            >
              <polyline
                points="0,27 80,27 80,80 80,80 80,100 160"
                stroke="#F2F9EF"
                strokeWidth="2px"
                fill="transparent"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}

          {/* TABLET */}
          {/* TABLET */}
          {/* TABLET */}
          {/* TABLET */}
          {typeof window !== "undefined" && state.viewport == "tablet" && (
            <svg
              height="200"
              width="600"
              {...props}
              sx={{
                position: `absolute`,
                ml: 5,
                mt: `5rem`,
                transition: `all 2s ease`,
                WebkitTransition: `all 2s ease`,
                MozTransition: `all 2s ease`,
              }}
            >
              <polyline
                points="140,80 350,80 350,160 350,160 350,140 680"
                stroke="#F2F9EF"
                strokeWidth="2px"
                fill="transparent"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}
          {/* laptop */}
          {/* laptop */}
          {/* laptop */}
          {/* laptop */}
          {typeof window !== "undefined" && state.viewport == "laptop" && (
            <svg
              height="250"
              width="800"
              {...props}
              sx={{
                position: `absolute`,
                ml: 6,
                mt: `5rem`,
                transition: `all 2s ease`,
                WebkitTransition: `all 2s ease`,
                MozTransition: `all 2s ease`,
              }}
            >
              <polyline
                points="170,135 500,135 500,230 500,230 500,200 680"
                stroke="#F2F9EF"
                strokeWidth="2px"
                fill="transparent"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}
          {/* LARGEDESKTOP */}
          {/* LARGEDESKTOP */}
          {/* LARGEDESKTOP */}
          {/* LARGEDESKTOP */}
          {typeof window != "undefined" && state.viewport == "largeLaptop" && (
            <svg
              height="200"
              width="400"
              {...props}
              sx={{
                position: `absolute`,
                // ml: 5,
                mt: `8.5rem`,
                left: `30%`,
                transition: `all 2s ease`,
                WebkitTransition: `all 2s ease`,
                MozTransition: `all 2s ease`,
              }}
            >
              <polyline
                points="0,80 400,80 400,"
                stroke="#F2F9EF"
                strokeWidth="2px"
                fill="transparent"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          )}
          {typeof window != "undefined" && state.viewport == "largeLaptop" && (
            <div
              {...props}
              sx={{
                color: marsTheme.colors.clay,
                display: `flex`,
                flexDirection: `column`,
                width: `full`,
                textAlign: `right`,
                position: `absolute`,
                right: `5%`,
                top: `38%`,
              }}
            >
              <h1
                {...props}
                sx={{
                  fontSize: [6, 6, 8, 100],
                  margin: `0`,
                  fontFamily: `Restora`,
                  lineHeight: [`.9`, `.9`, `1`, `1.25`],
                  fontWeight: `bold`,
                }}
              >
                TO LEAD
              </h1>

              <h2
                {...props}
                sx={{
                  fontSize: [6, 6, 8, 100],
                  letterSpacing: `wide`,
                  lineHeight: [`.9`, `.9`, `1`, `1.25`],
                  fontFamily: `Restora`,
                  fontStyle: `italic`,
                  alignSelf: `flex-end`,
                  m: 0,
                  fontWeight: `100`,
                }}
              >
                THE WORLD.
              </h2>
              <h3
                {...props}
                sx={{
                  color: marsTheme.colors.clay,
                  fontSize: [3, 3, 4, 4],
                  lineHeight: [`.9`, `.9`, `1`, `1.25`],
                  fontFamily: `Libre Baskerville`,
                  fontStyle: `italic`,
                  alignSelf: `flex-end`,
                  fontWeight: `400`,
                  mt: `1rem`,
                }}
              >
                Power &amp; Intimacy training <br />
                built to transform your life.
              </h3>
            </div>
          )}
          {typeof window != "undefined" && state.viewport != "largeLaptop" && (
            <div
              {...props}
              sx={{
                color: marsTheme.colors.clay,
                display: `flex`,
                flexDirection: `column`,
                width: `full`,
                textAlign: `right`,
                mt: [5, 0, 5, `5rem`],
                mr: [0, 0, 5, 5],
              }}
            >
              <h1
                {...props}
                sx={{
                  fontSize: [6, 6, 8, 8, 100],
                  margin: `0`,
                  fontFamily: `Restora`,
                  lineHeight: `1`,
                  fontWeight: `bold`,
                }}
              >
                TO LEAD
              </h1>

              <h2
                {...props}
                sx={{
                  fontSize: [6, 6, 8, 8, 100],
                  letterSpacing: `wide`,
                  lineHeight: `1`,
                  fontFamily: `Restora`,
                  fontStyle: `italic`,
                  alignSelf: `flex-end`,
                  m: 0,
                  fontWeight: `100`,
                }}
              >
                THE WORLD.
              </h2>
              <h3
                {...props}
                sx={{
                  color: marsTheme.colors.clay,
                  fontSize: [22, 18, 4, 4, 4],
                  lineHeight: [`1.2`, `1`, `1`, `1.25`],
                  fontFamily: `Libre Baskerville`,
                  fontStyle: `italic`,
                  alignSelf: `flex-end`,
                  fontWeight: `400`,
                  mt: 4,
                }}
              >
                Power &amp; Intimacy training <br />
                built to transform your life.
              </h3>
            </div>
          )}
        </Title>
        <div
          {...props}
          sx={{
            width: `95%`,
            zIndex: 51,
            display: `flex`,
            flexDirection: [`column`, `column`, `row`, `row`],
            height: `inherit`,
            justifyContent: [`center`, `flex-end`],
            textAlign: `center`,
            a: {
              my: 2,
              mx: 2,
              padding: `.75rem 2rem .75rem 2rem`,
              fontSize: 3,
              fontStyle: `italic`,
              fontWeight: `100`,
              fontFamily: `Libre Baskerville`,
            },
          }}
        >
          <a
            href="https://view.flodesk.com/pages/62b23107849e4b4fbbbea798"
            target="_blank"
            referrerPolicy="strict-origin-when-cross-origin"
            rel="noreferrer"
            {...props}
            sx={{
              color: marsTheme.colors.clay,

              // border: `1px solid ${marsTheme.colors.clay}`,
              borderRadius: `50px`,
              bg: marsTheme.colors.marsRed,
              transition: `all .5s ease`,
              WebkitTransition: `all .5s ease`,
              MozTransition: `all .5s ease`,
              "&:hover": {
                color: marsTheme.colors.black,
                bg: marsTheme.colors.clay,
              },
            }}
          >
            Join Newsletter
          </a>
          <a
            href="https://calendly.com/marscontract/book-a-session"
            target="_blank"
            referrerPolicy="strict-origin-when-cross-origin"
            rel="noreferrer"
            {...props}
            sx={{
              color: marsTheme.colors.black,
              bg: marsTheme.colors.clay,

              // border: `1px solid ${marsTheme.colors.clay}`,
              borderRadius: `50px`,
              transition: `all .5s ease`,
              WebkitTransition: `all .5s ease`,
              MozTransition: `all .5s ease`,
              "&:hover": {
                color: marsTheme.colors.clay,
                bg: marsTheme.colors.black,
              },
            }}
          >
            Book a Session
          </a>
        </div>
      </Content>
    </div>
  );
};

export default Hero;
