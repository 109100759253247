/** @jsx jsx */
// @ts-nocheck
import { jsx } from "theme-ui";
import { useContext, useEffect, useState, useRef } from "react";
import AboutContent from "../elements/aboutContent";
import { QuotesAnimated } from "../styles/animations";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { create } from "@lottiefiles/lottie-interactivity";

const Quotes = () => {
  const state = useContext(GlobalStateContext);
  const [isScrolled, setScrolled] = useState(null);
  const ref = useRef(null);

  const listenScrollEvent = (e) => {
    if (typeof window !== "undefined" && state.viewport == "laptop") {
      if (window.scrollY <= 3125) {
        setScrolled(false);
      } else if (window.scrollY > 3125) {
        setScrolled(true);
      } else {
      }
    } else if (
      typeof window !== "undefined" &&
      (state.viewport == "mobile" || state.viewport == "smallMobile")
    ) {
      if (window.scrollY <= 2300) {
        setScrolled(false);
      } else if (window.scrollY > 2300) {
        setScrolled(true);
      }
    } else if (
      typeof window !== "undefined" &&
      (state.viewport == `largeLaptop` || state.viewport == `desktop`)
    ) {
      if (window.scrollY <= 4440) {
        setScrolled(false);
      } else if (window.scrollY > 4440) {
        setScrolled(true);
      }
    } else if (
      typeof window !== "undefined" &&
      state.veiwport != "mobile" &&
      state.viewport != "laptop" &&
      state.viewport != "largeLaptop" &&
      state.viewport != "desktop"
    ) {
      if (window.scrollY <= 2610) {
        setScrolled(false);
      } else if (window.scrollY > 2610) {
        setScrolled(true);
      }
    }
  };
  const lottieScroll = (e) => {
    create({
      mode: "scroll",
      player: "#firstLottie",
      container: "#lottieWrapper",
      actions: [
        {
          visibility: [0, 0.1],
          type: "stop",
          frames: [0],
        },
        {
          visibility: [0.1, 1],
          type: "seek",
          frames: [0, 100],
        },
      ],
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    import("@lottiefiles/lottie-player");
    ref.current && ref.current.addEventListener("load", lottieScroll);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
      ref.current && ref.current.removeEventListener("load", lottieScroll);
    };
  });
  // TODO LOTTIE

  return (
    <div>
      <AboutContent>
        <QuotesAnimated>
          <div
            sx={{
              width: `full`,
              height: `full`,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              textAlign: `center`,
              mt: [`-6rem`, `-5rem`, 0, 0, -5],
            }}
          >
            <div
              sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: [
                  `space-around`,
                  `space-around`,
                  `space-around`,
                  `space-around`,
                  `center`,
                ],
                width: `full`,
                height: [1000, 1000, 1500, 1500, 1500],
                mt: [4, 4, 6, 5, 5],
                mb: [5, 5, 5, 7, 6],
                fontWeight: `bold`,
              }}
            >
              <div>
                <p
                  sx={{
                    lineHeight: `1.5`,
                    fontSize: [20, 20, 3, 4, `2.5rem`],
                    textAlign: [`right`, `right`, `right`, `right`],
                    marginRight: [`1.5rem`, `1.5rem`, 4, 5, `6rem`],
                    fontFamily: `Libre Baskerville`,
                    fontWeight: `normal`,
                    position: `relative`,
                    bottom: [0, 0, 200, 0, 0],
                  }}
                >
                  {" "}
                  <em>
                    "I&#39;ve always been the Nice Guy
                    <br />
                    in the friend zone. This work has
                    <br /> made me come out of my shell
                    <br /> &amp; I&#39;ve gained confidence in just
                    <br /> a few short sessions. Enjoy a new <br />
                    feeling of freedom... I am."
                    <br />
                    <br />
                    Steven C.
                  </em>
                </p>
              </div>
              <div>
                <p
                  sx={{
                    lineHeight: `1.5`,
                    fontSize: [20, 20, 3, 4, `2.5rem`],
                    color: marsTheme.colors.clay,
                    textAlign: `left`,
                    marginLeft: [`1.5rem`, `1.5rem`, 4, 5, `6rem`],
                    fontFamily: `Libre Baskerville`,
                    fontWeight: `normal`,
                    position: `relative`,
                    top: [50, 0, -150, 200, 100],
                  }}
                >
                  <em>
                    "You will be showing up <br />
                    an entirely different way around <br />
                    women &amp; having the experiences
                    <br />
                    you were hoping for. G. Lyonns <br />
                    can truly help you experience
                    <br /> transformation in your life."
                    <br />
                    <br />
                    Dr. Craig S.
                  </em>
                </p>
              </div>
            </div>

            <div
              sx={{
                mt: `3rem`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `space-between`,
                zIndex: 1,
              }}
            >
              <div
                sx={{
                  mb: `3rem`,
                }}
              >
                <p
                  sx={{
                    lineHeight: `1.25`,
                    fontSize: [2, 2, 4, 5],
                    color: marsTheme.colors.clay,
                    m: 0,
                  }}
                >
                  Discover
                  <br />
                  who you are
                  <br />
                  &amp; how your power works.
                  <br />
                  There you will find,
                  <br />
                  it is <em>endless</em>.
                </p>
              </div>
              <div
                id="lottieWrapper"
                sx={{
                  width: [`full`],
                }}
              >
                <lottie-player
                  id="firstLottie"
                  ref={ref}
                  mode="normal"
                  src="https://assets5.lottiefiles.com/packages/lf20_hlrscybj.json"
                  style={{ height: "50vh" }}
                ></lottie-player>
              </div>
            </div>

            <div sx={{ mb: [4, 4, 6, 6], zIndex: 1 }}>
              <div>
                <p
                  sx={{
                    fontFamily: `Libre Baskerville`,
                    fontSize: [1, 1, 3, 4],
                    mb: [1, 1, 2, 3],
                    lineHeight: `1`,
                    fontStyle: `italic`,
                    color: marsTheme.colors.clay,
                  }}
                >
                  Your future starts
                </p>
              </div>

              <a
                href="https://calendly.com/marscontract/discovery-call"
                target="_blank"
                referrerPolicy="strict-origin-when-cross-origin"
                rel="noreferrer"
                sx={{
                  fontSize: [`4rem`, `4rem`, `5rem`, `5rem`],
                  fontStyle: `italic`,
                  fontWeight: `100`,
                  fontFamily: `Libre Baskerville`,
                  color: marsTheme.colors.clay,
                  padding: `.5rem 2rem .5rem 2rem`,
                  border: `1px solid ${marsTheme.colors.clay}`,
                  borderRadius: `50px`,
                  bg: isScrolled ? marsTheme.colors.black : `none`,
                  transition: `all .5s ease`,
                  WebkitTransition: `all .5s ease`,
                  MozTransition: `all .5s ease`,
                  "&:hover": {
                    border: `5px solid ${marsTheme.colors.marsRed}`,
                    color: marsTheme.colors.marsRed,
                  },
                }}
              >
                Today.
              </a>
            </div>
          </div>
        </QuotesAnimated>
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {typeof window !== "undefined" &&
          (state.viewport == "mobile" || state.viewport == "smallMobile") && (
            <svg
              viewBox="0 0 431.16 1750.36"
              sx={{
                fill: isScrolled
                  ? marsTheme.colors.black
                  : marsTheme.colors.marsRed,
                position: `absolute`,
                mt: 6,
                zIndex: 0,
                transition: `all 2000ms ease`,
                WebkitTransition: `all 2000ms ease`,
                MozTransition: `all 2000ms ease`,
              }}
            >
              <polygon points="0 0 0 1850.36 471.16 1850.36 471.16 436.89 0 0" />
            </svg>
          )}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {typeof window !== "undefined" && state.viewport == "tablet" && (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 471.16 1650.36"
            sx={{
              fill: isScrolled
                ? marsTheme.colors.black
                : marsTheme.colors.marsRed,
              position: `absolute`,
              mt: 6,
              zIndex: 0,
              transition: `all 2000ms ease`,
              WebkitTransition: `all 2000ms ease`,
              MozTransition: `all 2000ms ease`,
            }}
          >
            <polygon points="0 0 0 1850.36 572.16 1850.36 572.16 436.89 0 0" />
          </svg>
        )}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {typeof window !== "undefined" && state.viewport == "laptop" && (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1050 2000"
            sx={{
              fill: isScrolled
                ? marsTheme.colors.black
                : marsTheme.colors.marsRed,
              position: `absolute`,

              zIndex: 0,
              transition: `all 2000ms ease`,
              WebkitTransition: `all 2000ms ease`,
              MozTransition: `all 2000ms ease`,
            }}
          >
            <polygon
              className="st0"
              points="0.5,2200 1300,2200 1500,1900 0.5,0.5 "
            />
          </svg>
        )}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {/* Adaptive Objects */}
        {typeof window !== "undefined" && state.viewport == "largeLaptop" && (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 800 1900"
            sx={{
              fill: isScrolled
                ? marsTheme.colors.black
                : marsTheme.colors.marsRed,
              position: `absolute`,
              zIndex: 0,
              transition: `all 2000ms ease`,
              WebkitTransition: `all 2000ms ease`,
              MozTransition: `all 2000ms ease`,
              mt: `20rem`,
            }}
          >
            <polygon className="st0" points="0,1600 1300,1600 1500,1600 0,0 " />
          </svg>
        )}
      </AboutContent>
    </div>
  );
};

export default Quotes;
