import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import About from "../components/about";
import Passages from "../components/passages";
import Contact from "../components/contact";
import Founder from "../components/founder";
import Quotes from "../components/quotes";

const MarsDemo = (props) => (
  <Layout>
    <Hero props={props}/>
    <About />
    <Quotes />
    <Passages />
    <Founder />
    <Contact />
  </Layout>
);

export default MarsDemo;
