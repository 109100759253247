/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type PackagesProps = {
  className?: string;
  children: React.ReactNode;
};

export const Packages = ({ className = ``, children }: PackagesProps) => (
  <div
    sx={{
      display: `flex`,
      flexDirection: [`column`, `column`, `column`, `column`],
      width: `100%`,
      alignItems: `center`,
      // alignItems: [`center`, `center`, "center", `baseline`, `baseline`],
      fontFamily: `Libre Baskerville`,
      my: `3rem`,
    }}
    className={className}
  >
    {children}
  </div>
);
