/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type InnerProps = {
  className?: string;
  children: React.ReactNode;
};

const Inner = ({ className = ``, children }: InnerProps) => (
  <div
    sx={{
      width: `full`,
      textAlign: `right`,
      // pb: `2rem`,
    }}
    className={className}
  >
    {children}
  </div>
);

export default Inner;
