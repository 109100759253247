/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type AboutContentProps = {
  children: React.ReactNode;
  className?: string;
};

const AboutContent = ({ children }: AboutContentProps) => (
  <div
    sx={{
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      justifyContent: `center`,
      zIndex: 50,
      mb: 5,
      mt: [2, 3, `12rem`, 6, `10rem`],
    }}
  >
    {children}
  </div>
);

export default AboutContent;
