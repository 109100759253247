/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useContext } from "react";
import Inner from "../elements/inner";
import Content from "../elements/content";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { Packages } from "../elements/packages";
import { Dainis, Alexas, Jeremy } from "../hooks/images";
import "../styles/passages.css";
import { GlobalStateContext } from "../context/GlobalContextProvider";

const Passages = (props) => {
  const state = useContext(GlobalStateContext);
  const [selfState, setSelfState] = useState(false);
  const [sexState, setSexState] = useState(false);
  const [sovState, setSovState] = useState(false);
  const selfClick = () => {
    setSelfState(!selfState);
  };
  const sexClick = () => {
    setSexState(!sexState);
  };
  const sovClick = () => {
    setSovState(!sovState);
  };

  return (
    <div>
      <Content>
        <Inner>
          <Packages>
            <div
              sx={{
                display: `flex`,
                mx: [3, 4, 4, 4, 5],
                top: [`auto`, `auto`, `812% `, `812%`],
                lineHeight: `1.5`,
                fontSize: [6, 6, 80, 100, 100],
                color: marsTheme.colors.clay,
                fontFamily: `Libre Baskerville`,
                alignSelf: [
                  `center`,
                  `center`,
                  `flex-start`,
                  `flex-start`,
                  `flex-start`,
                ],
              }}
            >
              <p>Practices</p>
            </div>

            <div
              sx={{
                display: `flex`,
                flexDirection: [`column`, `column`, `row`],
                color: marsTheme.colors.clay,
                // lineHeight: `1`,
                // ml: [0, 0, 4, 4],
                // mr: [0, 0, 0, 6, 7],
                my: 3,
                alignSelf: [`center`, `center`, `center `],
                textAlign: [`center`, `center`, `left`, `left`],
                lineHeight: `normal`,
              }}
            >
              <p
                sx={{
                  fontSize: [`1rem`, `1rem`, 2, 2, 3],
                  // textAlign: `left`,
                  // lineHeight: `normal`,
                  py: `1em`,
                  mx: [4, 4, 4, 4, 5],
                }}
              >
                This work is tailored and <em>designed</em> to find exactly how
                your power moves, where it escapes &amp; why. Our commitment is
                that you will reach the other side knowing <em>liberation.</em>{" "}
                See the range &amp; scope of our expertise to discover how we
                can assist you to reclaim your
                <strong> Self, Sex, &amp; Sovereignty.</strong>
              </p>
            </div>
            {/* {typeof window !== "undefined" &&
              (state.viewport == "mobile" ||
                state.viewport == "smallMobile" ||
                state.viewport == "tablet") && (
                <div
                  sx={{
                    mb: 4,
                    mt: 4,
                  }}
                >
                  <hr
                    sx={{
                      backgroundColor: `white`,
                      width: `2px`,
                      height: `150px`,
                    }}
                  />
                </div>
              )}
            {typeof window != "undefined" &&
              (state.viewport == "laptop" ||
                state.viewport == "largeLaptop" ||
                state.viewport == "desktop") && (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 580.2 437.1"
                  sx={{
                    position: `absolute`,
                    ml: 2,
                    mt: `5rem`,
                    transition: `all 2s ease`,
                    WebkitTransition: `all 2s ease`,
                    MozTransition: `all 2s ease`,
                  }}
                >
                  <polyline
                    stroke="#F2F9EF"
                    strokeWidth="1px"
                    fill="transparent"
                    points="250,7 489.7,7 489.7,165"
                  />
                </svg>
              )} */}
          </Packages>

          {/* SELF */}
          {/* SELF */}
          {/* SELF */}
          {/* SELF */}
          {/* SELF */}

          <div
            sx={{
              width: `100%`,
              display: `flex`,
              flexDirection: [`column`, `column`, `column`, `row`, `row`],
              justifyContent: `space-between`,
              alignItems: [
                `inherit`,
                `inherit`,
                `center`,
                `inherit`,
                `inherit`,
              ],
            }}
          >
            <div
              className="selfPassage"
              sx={{
                width: [`100%`, `100%`, "75%", `33%`, `33%`],
                background: `white`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-end`,
                alignItems: `center`,
                position: `relative`,
                mb: [5, 5, 0, 0],
              }}
            >
              <Jeremy />
              {selfState == true && (
                <div
                  className="self"
                  sx={{
                    zIndex: 2,
                    position: `absolute`,
                    color: marsTheme.colors.clay,
                    bottom: `15%`,
                    textAlign: `center`,
                    px: [2, 4],
                  }}
                >
                  <p>
                    Discover Who <em>You</em> Are <br></br>beyond Your{" "}
                    <em>Conditioning</em>
                  </p>
                  <p>
                    <em>Become</em> Naturally Magnetic to what you Desire
                  </p>
                  <p>
                    Transform Your "Nice Guy" <br></br>into a{" "}
                    <em>Man of Value</em>
                  </p>
                  <p>
                    Claim Your
                    <br /> <em>Innate</em> Courage
                  </p>
                  <p>
                    Identify your <em>Triggers</em> and achieve Emotional{" "}
                    <em>Liberation</em>
                  </p>
                  <p>
                    Receive the Keys to <em>Empowered</em> Communication
                  </p>
                  <p>
                    {" "}
                    &amp; <em>more...</em>
                  </p>
                  <a
                    href="https://calendly.com/marscontract/book-a-session"
                    target="_blank"
                    referrerPolicy="strict-origin-when-cross-origin"
                    rel="noreferrer"
                  >
                    <button
                      sx={{
                        bg: `transparent`,
                        height: `3rem`,
                        width: `10rem`,
                        border: `1px solid ${marsTheme.colors.clay}`,
                        borderRadius: `50px`,
                        fontFamily: `Libre Baskerville`,
                        lineHeight: `1`,
                        fontSize: `1.25rem`,
                        mb: 4,
                        mt: 2,
                        color: marsTheme.colors.clay,
                        textAlign: `center`,
                        "&:hover": {
                          color: marsTheme.colors.marsRed,
                        },
                      }}
                    >
                      <em>Book a Session</em>
                    </button>
                  </a>
                </div>
              )}
              <div
                // {...props}
                sx={{
                  width: `90%`,
                  alignItems: `center`,
                  position: `absolute`,
                  zIndex: 4,
                  display: `flex`,
                  justifyContent: `space-between`,
                  bottom: selfState ? 10 : 50,
                  padding: `1rem`,
                  textAlign: `center`,
                  border: selfState
                    ? `none`
                    : `1px solid ${marsTheme.colors.clay}`,
                  borderRadius: `4rem`,
                  transition: `all 0.70s ease`,
                  WebkitTransition: `all .70s ease`,
                }}
              >
                <h3
                  sx={{
                    m: `0`,
                    color: marsTheme.colors.clay,
                    fontFamily: `Restora`,
                    fontWeight: `bold`,
                    fontSize: [`2rem`, `2rem`, `3rem`, `1.5rem`, `2.5rem`],
                  }}
                >
                  <em>Self</em>
                </h3>
                <button
                  onClick={selfClick}
                  sx={{
                    bg: marsTheme.colors.clay,
                    height: [80, 80, 80, 60, 80],
                    width: [80, 80, 80, 60, 80],
                    borderRadius: `50px`,
                    border: `none`,
                    outline: `none`,
                  }}
                >
                  <p
                    sx={{
                      textAlign: `center`,
                      fontSize: [`3rem`, `3rem`, `3rem`, `2rem`, `3rem`],
                      m: 0,
                      color: marsTheme.colors.marsRed,
                      fontFamily: `Libre Baskerville`,
                      lineHeight: `1`,
                      transition: `all 1s ease`,
                      WebkitTransition: `all 1s ease`,
                    }}
                  >
                    {selfState ? `-` : `+`}
                  </p>
                </button>
              </div>
              {selfState == true && <div className="overlay overlayFade"></div>}
            </div>

            {/* SEX */}
            {/* SEX */}
            {/* SEX */}
            {/* SEX */}

            <div
              className="sexPassage"
              sx={{
                width: [`100%`, `100%`, "75%", `33%`, `33%`],
                background: `white`,
                position: `relative`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-end`,
                alignItems: `center`,
                m: 0,
                mb: [5, 5, 0, 0],
              }}
            >
              <Dainis />
              {sexState == true && (
                <div
                  className="sex"
                  sx={{
                    zIndex: 2,
                    position: `absolute`,
                    color: marsTheme.colors.clay,
                    bottom: `15%`,
                    textAlign: `center`,
                    px: [2, 2, 2, 2, 4],
                    transition: `all 2s ease`,
                    WebkitTransition: `all 2s ease`,
                    MozTransition: `all 2s ease`,
                  }}
                >
                  <p>
                    Ejaculation &amp; Erection <em>Control</em>
                  </p>
                  <p>
                    Improve Stamina &amp; Technique to Change Your Sex Life{" "}
                    <em>Forever</em>
                  </p>
                  <p>
                    Create <em>Natural</em> Sexual Comfort &amp; Confidence
                  </p>
                  <p>
                    Kink Education, Power Exchange <br></br>&amp;{" "}
                    <em>Liberation</em> from Sexual Shame
                  </p>
                  <p>
                    <em>Understand</em> Feminine Arousal, Pleasure &amp;
                    Attraction Dynamics
                  </p>
                  <p>
                    Autogynephilia <em>Counseling</em> &amp; <em>Advocacy</em>
                  </p>
                  <p>
                    Navigate <em>Ethical</em> Non-Monogamy &amp; Polyamorous
                    Relationships
                  </p>
                  <p>
                    {" "}
                    &amp; <em>more...</em>
                  </p>
                  <a
                    href="https://calendly.com/marscontract/book-a-session"
                    target="_blank"
                    referrerPolicy="strict-origin-when-cross-origin"
                    rel="noreferrer"
                  >
                    <button
                      sx={{
                        bg: `transparent`,
                        height: `3rem`,
                        width: `10rem`,
                        border: `1px solid ${marsTheme.colors.clay}`,
                        borderRadius: `50px`,
                        fontFamily: `Libre Baskerville`,
                        lineHeight: `1`,
                        fontSize: `1.25rem`,
                        mb: 4,
                        mt: 2,
                        color: marsTheme.colors.clay,
                        textAlign: `center`,
                        "&:hover": {
                          color: marsTheme.colors.marsRed,
                        },
                      }}
                    >
                      <em>Book a Session</em>
                    </button>
                  </a>
                </div>
              )}
              <div
                {...props}
                sx={{
                  width: `90%`,
                  alignItems: `center`,
                  position: `absolute`,
                  zIndex: 4,

                  display: `flex`,
                  justifyContent: `space-between`,

                  bottom: sexState ? 20 : 50,
                  padding: `1rem`,
                  textAlign: `center`,
                  border: sexState
                    ? `none`
                    : `1px solid ${marsTheme.colors.clay}`,

                  borderRadius: `4rem`,
                  transition: `all 0.70s ease`,
                  WebkitTransition: `all .70s ease`,
                }}
              >
                <h3
                  sx={{
                    fontSize: [`2rem`, `2rem`, `3rem`, `1.5rem`, `2.5rem`],
                    m: `0`,
                    color: marsTheme.colors.clay,
                    fontFamily: `Restora`,
                    fontWeight: `bold`,
                  }}
                >
                  <em>Sex</em>
                </h3>
                <button
                  onClick={sexClick}
                  sx={{
                    bg: marsTheme.colors.clay,
                    height: [80, 80, 80, 60, 80],
                    width: [80, 80, 80, 60, 80],
                    borderRadius: `50px`,
                    border: `none`,
                    outline: `none`,
                  }}
                >
                  <p
                    sx={{
                      fontFamily: `Libre Baskerville`,
                      textAlign: `center`,
                      fontSize: [`3rem`, `3rem`, `3rem`, `2rem`, `3rem`],
                      lineHeight: `1`,
                      m: 0,
                      color: marsTheme.colors.marsRed,
                      transition: `all 1s ease`,
                      WebkitTransition: `all 1s ease`,
                    }}
                  >
                    {sexState ? `-` : `+`}
                  </p>
                </button>
              </div>
              {sexState == true && <div className="overlay overlayFade"></div>}
            </div>
            {/* Sovereignty */}
            {/* Sovereignty */}
            {/* Sovereignty */}
            {/* Sovereignty */}
            <div
              className="sovPassage"
              sx={{
                width: [`100%`, `100%`, "75%", `33%`, `33%`],
                background: `white`,
                position: `relative`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-end`,
                alignItems: `center`,
                m: 0,
                mb: [0, 0, 0, 0],
              }}
            >
              <Alexas />
              {sovState == true && (
                <div
                  className="sov"
                  sx={{
                    zIndex: 2,
                    position: `absolute`,
                    color: marsTheme.colors.clay,
                    bottom: `15%`,
                    textAlign: `center`,
                    px: [2, 4],
                    transition: `all 2s ease`,
                    WebkitTransition: `all 2s ease`,
                    MozTransition: `all 2s ease`,
                  }}
                >
                  <p>
                    Define, Polish
                    <br /> &amp; Hone Your <em>Alpha/Sigma</em> energy
                  </p>
                  <p>
                    Reclaim your Power by taking <em>Personal</em>{" "}
                    Responsibility
                  </p>
                  <p>
                    Cultivate <em>Unquestionable</em>
                    <br /> Integrity
                  </p>
                  <p>
                    Attain Genuine <em>Freedom</em> <br />
                    Through Self-Discipline
                  </p>
                  <p>
                    Discover and Cement Your
                    <br /> Life&#39;s <em>Legacy</em>
                  </p>
                  <p>
                    Fully Realize Your <br></br>
                    <em>Purpose</em>, <em>Potency</em>
                  </p>
                  <p>
                    {" "}
                    &amp; <em>more...</em>
                  </p>
                  <a
                    href="https://calendly.com/marscontract/book-a-session"
                    target="_blank"
                    referrerPolicy="strict-origin-when-cross-origin"
                    rel="noreferrer"
                  >
                    <button
                      sx={{
                        bg: `transparent`,
                        height: `3rem`,
                        width: `10rem`,
                        border: `1px solid ${marsTheme.colors.clay}`,
                        borderRadius: `50px`,
                        fontFamily: `Libre Baskerville`,
                        lineHeight: `1`,
                        fontSize: `1.25rem`,
                        mb: 4,
                        mt: 2,
                        color: marsTheme.colors.clay,
                        textAlign: `center`,
                        "&:hover": {
                          color: marsTheme.colors.marsRed,
                        },
                      }}
                    >
                      <em>Book a Session</em>
                    </button>
                  </a>
                </div>
              )}
              <div
                {...props}
                sx={{
                  width: `90%`,
                  alignItems: `center`,
                  position: `absolute`,
                  zIndex: 4,
                  display: `flex`,
                  justifyContent: `space-between`,
                  bottom: sovState ? 20 : 50,
                  padding: `1rem`,
                  textAlign: `center`,
                  border: sovState
                    ? `none`
                    : `1px solid ${marsTheme.colors.clay}`,

                  borderRadius: `4rem`,
                  transition: `all 0.70s ease`,
                  WebkitTransition: `all .70s ease`,
                }}
              >
                <h3
                  sx={{
                    fontSize: [`2rem`, `2rem`, `3rem`, `1.5rem`, `2.5rem`],
                    m: `0`,
                    color: marsTheme.colors.clay,
                    fontFamily: `Restora`,
                    fontWeight: `bold`,
                  }}
                >
                  <em>Sovereignty</em>
                </h3>
                <button
                  onClick={sovClick}
                  sx={{
                    bg: marsTheme.colors.clay,
                    height: [80, 80, 80, 60, 80],
                    width: [80, 80, 80, 60, 80],
                    borderRadius: `50px`,
                    border: `none`,
                    outline: `none`,
                  }}
                >
                  <p
                    sx={{
                      fontFamily: `Libre Baskerville`,
                      color: marsTheme.colors.marsRed,
                      textAlign: `center`,
                      fontSize: [`3rem`, `3rem`, `3rem`, `2rem`, `3rem`],
                      lineHeight: `1`,
                      m: 0,
                      transition: `all 1s ease`,
                      WebkitTransition: `all 1s ease`,
                    }}
                  >
                    {sovState ? `-` : `+`}
                  </p>
                </button>
              </div>
              {sovState == true && <div className="overlay overlayFade"></div>}
            </div>
          </div>
        </Inner>
      </Content>
    </div>
  );
};

export default Passages;
