import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";

//! key frames for the about section wrapper

const aboutSection = keyframes`
from {
  transform: translateY(0); 
}
to {
  transform: translateY(15px);
}
`;

const upDown = keyframes`
  from {
    transform: translate3d(0, 15px, 30px);
    transform: scale(1.1,1.1)
  }
  to {
   
    transform: translate3d(0, 0, 0);
    transform: scale(1,1);
  }
`;

const upDownWide = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(15px);
  }
`;

const aboutSectionAnimation = css`
  ${aboutSection} 4s ease-in-out infinite alternate;
`;
const quotesSectionAnimation = css`
  ${aboutSection} 5s ease-in-out infinite alternate;
`;

const upDownAnimation = css`
  ${upDown} 4s ease-in-out 1 alternate;
`;

const upDownWideAnimation = css`
  ${upDownWide} 4s ease-in-out infinite alternate;
`;

export const AboutAnimated = styled.div`
  animation: ${aboutSectionAnimation};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 1;
  flex-direction: row;
  height: 100%;
  @media (min-width: 1200px) {
    width: 90%;
  }
`;
export const QuotesAnimated = styled.div`
  animation: ${quotesSectionAnimation};
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 900px) {
    margin-top: 10rem;
  }
`;

export const UpDown = styled.div`
  animation: ${upDownAnimation};
`;

export const UpDownWide = styled.div`
  animation: ${upDownWideAnimation};
  width: 40%;
  display: flex;
  flex-direction: column;
`;
