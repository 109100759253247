/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";

type ContentProps = {
  children: React.ReactNode;
  className?: string;
  bgColor?: string;
  fontFamily?: string;
  color?: string;
};

const Content = ({
  children,
  className = `Content`,
  bgColor,
  fontFamily,
  color,
}: ContentProps) => (
  <div
    sx={{
      paddingBottom: [0, 0, 0, 0, 0],
      paddingTop: [0, 0, 0, 4, 0],
      paddingRight: [0, 0, 0, 0, 0],
      paddingLeft: [0, 0, 0, 0, 0],
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      justifyContent: `center`,
      zIndex: 50,
      backgroundColor: bgColor,
      fontFamily: fontFamily,
      color: color,
    }}
  >
    {children}
  </div>
);

export default Content;
